@import '~antd/dist/antd.less';

body,
#root {
  height: 100%;
  font-family: 'Inter', sans-serif;
}

.ant-typography {
  color: #7d80a1;
}

.ant-menu-item-selected {
  background-color: #f1f4f9 !important;
}

.ant-skeleton-content .ant-skeleton-paragraph > li,
.ant-skeleton-title {
  background-color: #f3f5fb !important;
}

.ant-message-info {
  display: flex;
  align-items: center;

  .info-message-container {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .close-icon-btn {
      border: none;
      box-shadow: none;
      cursor: pointer;
    }

    .remind-me-btn {
      background: none;
      cursor: pointer;
      color: #08bbd3;

      span {
        text-decoration: underline;
      }

      &:hover {
        color: #0a183a;
      }
    }

    .anticon {
      font-size: 20px;
    }
    p {
      margin: 0;
    }
  }
}

.ant-layout {
  background: transparent;
  .ant-menu-submenu-title {
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    color: #c7cde5;

    &:hover {
      color: #180c2c;
    }
  }
}
.custom-error-message {
  .ant-message-notice-content {
    color: white;
    background: #ff2a44;
    padding: 8px 16px;
  }
}
.custom-success-message {
  .ant-message-notice-content {
    padding: 16px;
    border-radius: 6px;
    border-top: 5px solid #08bbd3;

    .ant-message-success {
      display: flex;
      svg {
        margin-right: 16px;
      }
    }
  }
}
.custom-sub-menu {
  &&& ul {
    border-radius: 20px;
    padding: 14px 2px;
    li {
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        margin-right: 16px;
      }
      &:active {
        background: none;
      }
    }
  }
}
.custom-table-actions {
  .ant-menu-submenu-title {
    &:active {
      background: none;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.pointer-event-none {
  pointer-events: none;
  .custom-table-actions {
    pointer-events: auto;
  }
}
.custom-tooltip {
  .ant-tooltip-inner {
    color: #0a183a;
    padding: 6px 16px;
    border-radius: 4px;
  }
}

.questionMenu {
  padding-top: 96px !important;
  .ant-menu-item-selected {
    &::after {
      transform: none !important;
      opacity: 1 !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
}

.ant-table {
  background-color: transparent;
  .ant-table-thead th {
    color: #7d80a1;
  }
  .results-header-col {
    width: 50%;
  }
  table {
    border-spacing: 0 5px;
    tr > td:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    tr > td:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    tr > th {
      background-color: transparent;
      border: none;
      &:hover {
        background-color: transparent !important;
      }
    }

    tbody tr {
      height: 56px;
      border-radius: 12px;
      box-shadow: 0px 10px 30px 2px #dbe5f3;
    }

    tbody td {
      padding: 0px 16px;
      background-color: #fff;
    }

    tbody ul {
      background-color: transparent;
      border: none;
    }

    .ant-menu-submenu-title {
      padding: 0 !important;
      margin: 0 !important;
      line-height: normal !important;
      height: 20px !important;
      text-align: right;
    }

    .ant-menu {
      width: max-content;
      float: right;
      clear: both;
    }
  }
}

.adsModal {
  .no-height {
    height: 0px;
    overflow: hidden;
    margin: 0;
  }

  .ant-upload-picture-card-wrapper {
    width: 100%;
    max-height: 76px;
    overflow: hidden;
  }

  .ant-upload-select-picture-card {
    border: none !important;
    border-radius: none !important;
    background-color: #fff !important;
    max-height: 76px;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .uploadFormItem {
    max-height: 170px;
    position: relative;
    overflow-y: auto;
    margin-bottom: 32px;
  }

  .imgDiv {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: 76px;
      border: 1px solid #c7cde5 !important;
      border-radius: 12px;
    }

    span {
      display: none;
    }

    &:hover span {
      display: inline-block;
      position: absolute;
      top: 1px;
      right: 2px;
      border: 1px solid #eee;
      border-radius: 50%;
      background: #fff;
      padding: 1px;
      cursor: pointer;
    }
  }
}

.vertical-group {
  label {
    display: block;
    span {
      display: inline-block;
    }
  }

  .ant-checkbox {
    line-height: 0;
  }
}

.ant-checkbox-inner,
.ant-select-dropdown {
  border-radius: 2px !important;
}

.scrollable {
  &::before {
    top: unset;
  }
  height: fit-content;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &.miniScroll::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
  }
}

.seven-star ul {
  width: max-content;
}

@primary-color: #0C1740;@border-radius-base: 30px;@text-color-secondary: #7D80A1;